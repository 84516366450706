import Image from 'next/image';
import Link from 'next/link';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import styles from '../../styles/components/header.module.scss';
import styles2 from '../../styles/components/pst/header-pst.module.scss';
import LoginBox from '../login-box';
import { useRouter } from 'next/router';
import Icon from '../base/icon';
import { UserContext } from '../user-provider';
import UserImageContainer from '../base/user-image-container';
import {
  AGENCY_CATEGORY_ID_FOR_PST,
  ROLES,
  TOURIST_SERVICE_TYPE,
} from '../../shared/constants';
import { getCurrentUserRole, signOut } from '../../services/cognito';
import classNames from 'classnames';
import mobileMenuStyles from '../../styles/molecules/mobile-menu.module.scss';
import LanguageSelection from '../base/language-selection';
import { BigSearchBox } from '../big-search-box';
import { GeneralConfigContext } from '../general-config-provider';
import MenuNotificaciones from '../base/MenuNotificaciones';
import { apiNotificationGet } from '../../services/requestNotificacion';
import { apiRestGet, apiRestPost } from '../../services/request';

type HeaderProps = {
  pageType: number;
};
type Notification = {
  id: number;
  serviceName?: string;
  clientName?: string;
  reservationDate?: string;
  idOferta?: number;
  notificationType: number;
  wellRead: number;
};

const HeaderPst: FunctionComponent<HeaderProps> = ({ pageType }) => {
  const router = useRouter();

  const { user } = useContext(UserContext);
  const { generalConfig } = useContext(GeneralConfigContext);

  const [showNotifications, setShowNotifications] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [profilePath, setProfilePath] = useState('/');
  const [notification, setNotification] = useState<Notification[] | []>([]);
  const [token, setToken] = useState<string>("");

  const [showmore, setShowMore] = useState(false);

  const signOutUser = async () => {
    await signOut();
    setShowLogoutPopup(false);
    router.push('/negocios');
  };

  const showLoginBoxOnMobile = () => {
    setShowMobileMenu(false);
    setShowLoginPopup(true);
  };

  const NotificationSet = async () => {
    apiNotificationGet({ id_user: user.info.perfil.id_PST })
      .then((response) => {
        setNotification(response.data);
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };

  const bankData = async () => {
    let response = await apiRestGet('user/getBankData', {
      id_pst: user?.info?.perfil?.id_PST,
    });

    if (response?.data) {
      return;
    }
    await apiRestPost('user/createBankData', {
      codigo: '',
      id_pst: user.info.perfil.id_PST,
      private_key: '',
      public_key: '',
      pasarela: 0,
    });
  };

  const dataToken = () => {
    const token = {
      accessToken: user.signInUserSession.accessToken.jwtToken,
      idToken: user.signInUserSession.idToken.jwtToken,
      refreshToken: user.signInUserSession.refreshToken.token
    }
    const tokenJson = JSON.stringify(token)
    const tokenCode = btoa(tokenJson)
    setToken(tokenCode)
  }

  useEffect(() => {
    const handleRouteChange = () => setShowMobileMenu(false);
    router.events.on('beforeHistoryChange', () => handleRouteChange);
    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
      setToken("");
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (user === null || user === undefined) {
        return;
      }
      await bankData();
      dataToken();
      if ((await getCurrentUserRole()) === ROLES.TOURIST) {
        setProfilePath('/cuenta?tab=1');
      }

      if ((await getCurrentUserRole()) === ROLES.PST) {
        setProfilePath('/negocios/datos-de-empresa');
      }
      NotificationSet().then();
    })();
  }, [user]);

  const navLinks = [];

  navLinks[ROLES.TOURIST] = [
    { name: 'Región Pacífica', pathname: '/' },
    { name: 'Actividades', pathname: '/' },
    { name: 'Destinos', pathname: '/' },
    { name: 'Experiencias', pathname: '/' },
    { name: 'Eventos', pathname: '/eventos' },
  ];

  navLinks[ROLES.PST] = [
    {
      icon: 'xxx',
      name: 'Ubicación',
      pathname: '/negocios/ubicacion',
    },
    {
      icon: 'store',
      name: 'Servicios turísticos',
      pathname: '/negocios/ofertas',
      query: { tipo: 'servicio' },
    },
    {
      icon: 'store',
      name: 'Paquetes turísticos',
      pathname: '/negocios/ofertas',
      query: { tipo: 'paquete' },
    },
    {
      icon: 'notifications',
      name: 'Notificaciones',
      pathname: '/',
    },
  ];

  return (
    <div className={styles.headerContainer} role="navigation">
      <BigSearchBox
        isOpened={showSearchBox}
        onBigSearchBoxClosed={() => setShowSearchBox(false)}
      />

      {showLoginPopup && (
        <LoginBox handleClose={() => setShowLoginPopup(false)} />
      )}

      {showLogoutPopup && (
        <div className="modalContainer">
          <div className={classNames('modal', styles.logoutPopup)}>
            <p>¿Estás seguro que quieres cerrar sesión?</p>

            <div className={styles.options}>
              <button
                className="btn outline-primary"
                onClick={() => setShowLogoutPopup(false)}
              >
                No
              </button>
              <button className="btn primary" onClick={() => signOutUser()}>
                Sí
              </button>
            </div>
          </div>
        </div>
      )}

      {user ? (
        <div className={styles2.headerm}>
          <div>
            <div>
              <a href="/negocios">
                <img
                  className={styles2.logo}
                  src="/images/logo-pacifico-colombia.svg"
                  alt="Logo Pacifico colombia"
                />
              </a>
            </div>
            <div>
              <div>
                <div className={styles2.textResponsive}>
                  <p>
                    Portal de servicios turísticos -{' '}
                    {
                      generalConfig?.categoria_pst.find(
                        (category) =>
                          Number(category.id_categoria_PST) ===
                          Number(user.info.perfil.id_categoria_PST)
                      )?.nombre
                    }
                  </p>
                </div>
                <div>
                  <ul className={styles2['list-1']}>
                    <li>
                      <span
                        className={classNames(styles2.notification)}
                        style={{
                          backgroundColor: showNotifications
                            ? '#4F75CF'
                            : '#274488',
                        }}
                      >
                        <a
                          onClick={() =>
                            showNotifications
                              ? setShowNotifications(false)
                              : setShowNotifications(true)
                          }
                        >
                          <img src="/images/svg/icon-7.svg" alt="" />
                          {notification?.length > 0 ? (
                            <img
                              style={{
                                position: 'absolute',
                                paddingLeft: '8px',
                                marginTop: '-10px',
                              }}
                              src="/images/svg/puntoAmarillo.svg"
                              alt=""
                            />
                          ) : null}
                          <span className={styles2.textResponsive}>
                            Notificaciones
                          </span>
                        </a>
                        <div
                          style={{
                            visibility: showNotifications
                              ? 'visible'
                              : 'hidden',
                          }}
                          className={styles2.dr_menu}
                        >
                          <MenuNotificaciones itemMenu={notification} />
                        </div>
                      </span>
                    </li>
                    <li>
                      <span
                        id={styles2.menuc}
                        className={classNames(
                          styles2.avatar,
                          styles2.textResponsive
                        )}
                      >
                        <span>
                          <Link href={profilePath}>Cuenta</Link>
                        </span>
                        {user && (
                          <UserImageContainer
                            imageName={user.info?.perfil?.imagen}
                            type="header"
                          />
                        )}
                      </span>
                      <span>
                        <button
                          style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            margin: '0 2rem',
                            height: '100%',
                            color: 'transparent',
                          }}
                          onClick={() => setShowMobileMenu(!showMobileMenu)}
                        >
                          .
                          <Icon icon="menu" size={24} color="#233E7F" />
                        </button>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={classNames(
                  styles2.displayNone,
                  showMobileMenu ? styles2.menuMovil : ''
                )}
              >
                <ul className={classNames(styles2['list-2'])}>
                  <li className={styles2.cuentaMenu}>
                    <Link href={'/negocios/datos-de-empresa'}>
                      <a
                        className={
                          window.location.pathname.includes('Datos empresa')
                            ? styles2.active
                            : null
                        }
                      >
                        <Icon icon="user-profile" size={25} color={'FFFFFF'} />
                        <span>Cuenta </span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href={'/negocios/ubicacion'}>
                      <a
                        className={
                          window.location.pathname.includes('Ubicación')
                            ? styles2.active
                            : null
                        }
                      >
                        <img src="/images/svg/icon-1.svg" alt="" />
                        <span>Ubicación </span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href={'/negocios/ofertas'}>
                      <a
                        className={
                          window.location.pathname.includes('ofertas')
                            ? styles2.active
                            : null
                        }
                      >
                        <img src="/images/svg/icon-2.svg" alt="" />
                        <span>Servicios turísticos</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href={'/negocios/reservas'}>
                      <a
                        className={
                          window.location.pathname.includes('reservas')
                            ? styles2.active
                            : null
                        }
                      >
                        <img src="/images/svg/icon-3.svg" alt="" />
                        <span>Ventas</span>
                      </a>
                    </Link>
                  </li>
                  {Number(user.info.perfil.id_categoria_PST) !=
                    TOURIST_SERVICE_TYPE.GASTRONOMY &&
                    Number(user.info.perfil.id_categoria_PST) !=
                      TOURIST_SERVICE_TYPE.TRANSPORT && (
                      <>
                        <li>
                          <Link href={'/negocios/datos-bancarios'}>
                            <a
                              className={
                                window.location.pathname.includes(
                                  'datos-bancarios'
                                )
                                  ? styles2.active
                                  : null
                              }
                            >
                              <img src="/images/svg/icon-4.svg" alt="" />
                              <span>Transacciones en línea</span>
                            </a>
                          </Link>
                        </li>
                      </>
                    )}
                  <li>
                    <Link href={'/negocios/cupones'}>
                      <a
                        className={
                          window.location.pathname.includes('cupones')
                            ? styles2.active
                            : null
                        }
                      >
                        <img src="/images/svg/icon-5.svg" alt="" />
                        <span>Cupones</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        showmore ? setShowMore(false) : setShowMore(true);
                      }}
                    >
                      <img src="/images/svg/icon-6.svg" alt="" />
                      <span>Más</span>
                    </a>
                    <div
                      style={{ visibility: showmore ? 'visible' : 'hidden' }}
                      className={styles2.dr_menu}
                    >
                      <div className={styles2.menu}>
                        <ul>
                          <li>
                            <a href={'/negocios/informacion'}>
                              <img src="/images/svg/info.svg" alt="" />
                              <span>Información</span>
                            </a>
                          </li>
                          <li>
                            <a href={'/negocios/temporadas'}>
                              <img src="/images/svg/info.svg" alt="" />
                              <span>Temporadas</span>
                            </a>
                          </li>
                          <li>
                            <a href={process.env.NEXT_PUBLIC_SIG_FRONT_URL + "?data="+token}>
                              <img src="/images/svg/sig.svg" alt="" />
                              <span>SIG</span>
                            </a>
                          </li>
                          <li>
                            <a href={process.env.NEXT_PUBLIC_PORTAL_IDEAS_INNOVADORAS+ "/"+token}>
                              <img src="/images/svg/idea.svg" alt="" />
                              <span>Portal de ideas innovadoras</span>
                            </a>
                          </li>
                          <li>
                            <a onClick={() => setShowLogoutPopup(true)}>
                              <img src="/images/svg/logout.svg" alt="" />
                              <span>Cerrar sesión</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles2.headerx}>
          <div>
            <div>
              <Link href="/negocios">
                <a href="">
                  <img
                    className={styles2.logo}
                    src="/images/logo-pacifico-colombia.svg"
                    alt="Logo Pacifico colombia"
                  />
                </a>
              </Link>
            </div>
            <div>
              <a type="button" href="/negocios/login" className={styles2.blue}>
                Inicia sesión
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderPst;
