import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import styles from '../styles/components/footer.module.scss';
import Icon from './base/icon';

export default function Footer(props) {
  const currentDate = new Date();
  const t = useTranslations('Footer');

  return (
    <footer className={styles.footer}>
      <div className={styles.associateLogos}>
        <Image
          alt="RAP"
          src="/images/LG_RAP.png"
          width="151px"
          height="63px"
          layout="fixed"
          loading="lazy"
        />
        <Image
          alt="Cauca"
          src="/images/LG_CAUCA.png"
          width="131px"
          height="67px"
          layout="fixed"
          loading="lazy"
        />
        <Image
          alt="Choco"
          src="/images/LG_CHOCO.png"
          width="129px"
          height="54px"
          layout="fixed"
          loading="lazy"
        />
        <Image
          alt="Nariño"
          src="/images/LG_NARINO.png"
          width="131px"
          height="73px"
          layout="fixed"
          loading="lazy"
        />
        <Image
          alt="Valle"
          src="/images/LG_VALLE.png"
          width="131px"
          height="47px"
          layout="fixed"
          loading="lazy"
        />
        <Image
          alt="SGR"
          src="/images/sgr.png"
          width="131px"
          height="62px"
          layout="fixed"
          loading="lazy"
        />
      </div>

      <div className={styles.middleSection}>
        <div className={styles.logoContainer}>
          <Image
            alt="Logo Pacífico Colombia"
            src={'/images/logo-pacifico-colombia-vertical.svg'}
            layout="fill"
          />
        </div>

        <div className={styles.links}>
          <div className={styles.firstDiv}>
            <ul>
            <li>
                <Link href="/ayuda">
                  <a href="">Ayuda</a>
                </Link>
              </li>
              <li>
                <Link href="/informacion">
                  <a href="">Información</a>
                </Link>
              </li>
              <li>
                <Link href="/admin/login">
                  <a href="">Acceso Administrador</a>
                </Link>
              </li>
              <li>
                <Link href="negocios/registro">
                  <a href="">Registrar mis productos y<br/>servicios turísticos</a>
                </Link>
              </li>

            </ul>

            <Link href="/negocios">
              <a href="" className="btn yellow">
                {t('businessLink')}
              </a>
            </Link>

            <ul>
              <li className={styles.facebook}>
                <Link href="https://www.facebook.com/PacificoColombia">
                  <a href="https://www.facebook.com/PacificoColombia">
                    Enlace a Facebook
                    <Icon icon="facebook" size={24} />
                  </a>
                </Link>
              </li>

              <li className={styles.youtube}>
                <Link href="https://www.youtube.com/@pacificocolombia9579">
                  <a href="https://www.youtube.com/@pacificocolombia9579">
                    Enlace a YouTube
                    <Icon icon="youtube" size={24} />
                  </a>
                </Link>
              </li>

              <li className={styles.instagram}>
                <Link href="https://www.instagram.com/pacificocolombia/">
                  <a href="https://www.instagram.com/pacificocolombia/">
                    Enlace a Instagram
                    <Icon icon="instagram" size={24} />
                  </a>
                </Link>
              </li>
            </ul>
          </div>
          <div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div className={styles.secondDiv}>

              <ul>

                <li>
                  <Link href="">
                    <a href="">Créditos de imágenes</a>
                  </Link>
                </li>
                <li>
                  <Link href="/terminos-y-condiciones">
                    <a href="">Términos, condiciones y <br/> política de privacidad</a>
                  </Link>
                </li>

                <li>
                  <Link href="/experiencia">
                    <a href="">Experiencias Realidad Virtual, <br/> Aumentada y Mixta</a>
                  </Link>
                </li>
              </ul>

          </div>
        </div>

        <div className={styles.appLinks}>
          <p>{props.greeting}</p>
          <p>{t('download')}</p>

          <div className={styles.images}>
            <Link href="https://apps.apple.com/us/app/turismo-regi%C3%B3n-pac%C3%ADfico/id1602719393">
              <a href="https://apps.apple.com/us/app/turismo-regi%C3%B3n-pac%C3%ADfico/id1602719393" target='_blank'>
                <Image
                  alt="Logo App Store"
                  src="/images/app-store.svg"
                  height="45px"
                  width="134px"
                />
              </a>
            </Link>

            <Link href="https://play.google.com/store/apps/details?id=com.turismo.prod&pli=1">
              <a href="https://play.google.com/store/apps/details?id=com.turismo.prod&pli=1" target='_blank'>
                <Image
                  alt="Logo Play Store"
                  src="/images/google-play.svg"
                  height="45px"
                  width="147px"
                />
              </a>
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.footerEnd}>
        <p>{`© ${t(
          'rights'
        )} ${currentDate.getFullYear()} - Turismo Región Pacífico`}</p>
      </div>
    </footer>
  );
}
